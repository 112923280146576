import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loginSchema } from '../../utils/RegisterYup';
import { useDoLoginMutation } from '../../redux/services/register/api';
import { useNavigate } from 'react-router-dom';
import { Slide, toast } from 'react-toastify';
import { showToast } from '../../utils/ErrorToast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const initialValues = {
  Mobile: '',
  Password: '',
};

type LoginProps = {
  onSignupClick: () => void;
};
const Login = ({ onSignupClick }: LoginProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [doLogin, { isLoading }] = useDoLoginMutation();

  const { values, errors, handleChange, handleBlur, handleSubmit, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: values => {
        doLogin({ mobileNumber: values?.Mobile, password: values?.Password })
          .unwrap()
          .then(res => {
            if (res?.success) {
              localStorage.setItem('token', res?.data?.token ?? '');
              navigate('/home', { replace: true });
            } else {
              showToast(res?.data?.message, 'error');
            }
          })
          .catch(() => {
            showToast('Something went wrong', 'error');
          });
      },
    });
  const EmailVerification = () => {
    navigate('/emailVerification');
  };

  return (
    <div className=" flex flex-col justify-center w-full">
      <div className="relative ">
        <form onSubmit={handleSubmit}>
          <div className="relative flex justify-center   bg-white h-[500px] ">
            <div className=" border border-red-100 rounded-3xl mt-12 px-10 py-10">
              <div>
                <h1 className="text-2xl font-semibold font-Onest justify-center flex ">
                  {t('registerDetails.login.title')}
                </h1>
              </div>
              <div className="divide-x divide-gray-200 mt-2">
                <div className="text-base leading-6 space-y-5 text-gray-700  ">
                  <div className="sm:col-span-3 mt-10 ">
                    <label
                      htmlFor="Mobile"
                      className="block text-sm font-medium leading-6 text-gray-900 font-Onest">
                      {t('registerDetails.login.mobile')}
                    </label>
                    <div className="">
                      <input
                        type="text"
                        maxLength={10}
                        name="Mobile"
                        id="Mobile"
                        autoComplete="given-name"
                        className="block w-64 outline-none font-Onest sm:w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={values.Mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.Mobile && touched.Mobile ? (
                        <p className="text-red-500 font-Onest text-xs mt-1">
                          {errors.Mobile}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="sm:col-span-2 ">
                    <label
                      htmlFor="Password"
                      className="block text-sm font-medium leading-6 text-gray-900 font-Onest">
                      {t('registerDetails.login.password')}
                    </label>
                    <div className="mt-2 flex">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        name="Password"
                        id="Password"
                        autoComplete="given-name"
                        className="block  w-64 font-Onest sm:w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={values.Password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className="relative top-2 pb-1  h-6  right-9  justify-center items-center flex rounded-sm flex-row gap-1 ">
                        <FontAwesomeIcon
                          className="text-gray-400"
                          icon={showPassword ? faEyeSlash : faEye}
                          onClick={() => setShowPassword(prev => !prev)}
                        />
                      </div>
                    </div>
                    {errors.Password && touched.Password ? (
                      <p className="text-red-500 font-Onest text-xs mt-1">
                        {errors.Password}
                      </p>
                    ) : null}
                  </div>
                  <div
                    className=" text-gray-400 w-full font-Onest text-xs text-end"
                    onClick={EmailVerification}>
                    <span className=" text-black w-full font-Onest text-xs  text-end mr-6 underline underline-offset-4 cursor-pointer">
                      Forget Password
                    </span>
                  </div>

                  <div className="relative pt-2">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="bg-red-500 w-64 text-white rounded-md sm:w-72 py-2 font-Onest font-bold flex content-center justify-center">
                      {isLoading ? (
                        <div className="flex flex-row justify-center items-center gap-2">
                          <svg
                            aria-hidden="true"
                            className="w-8 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="font-Onest text-sm"> Loading</span>
                        </div>
                      ) : (
                        t('registerDetails.login.title')
                      )}
                    </button>
                  </div>
                  <div className="">
                    <div
                      className=" text-gray-400 w-full font-Onest text-xs text-center"
                      onClick={onSignupClick}>
                      {t('registerDetails.login.message')}
                      <span className=" text-black w-full font-Onest text-xs  text-center underline underline-offset-4 cursor-pointer">
                        {'  '}
                        Sign Up !
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
